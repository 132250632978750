#profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;

    #profile-picture-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 160px;
        height: 160px;
        border-radius: 80px;
        margin-bottom: 12px;
        overflow: hidden;

        img {
            width: 160px;
            height: 160px;
            object-fit: contain;
        }
    }

    .profile-form-field {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .label {
            padding-left: 16px;
            font-size: 12px;
            padding-bottom: 6px;
        }
    }

    button {
        margin-top: 16px;
    }

    .terms {
        margin-top: 32px;
        width: calc(100% - 32px);
        padding-left: 16px;
        padding-right: 16px;
        text-align: center;
        font-size: 10px;
    }
}
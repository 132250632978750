@use "../../colors";

#signin {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    #signin-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;

        .signin-form-input-text {

            background-color: colors.$tertiary;
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 80vw;
            width: 380px;
            height: 60px;
            border-radius: 16px;
            padding: 0px 20px 0px 20px;
            gap: 14px;
            cursor: text;

            input {
                width: 100%;
                background-color: transparent;
                color: colors.$secondary;
                font-size: 14px;
                font-weight: 400;
                outline: 0px;
                border: 0px;
            }
        }

        .signin-form-input-checkbox {

            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 18px;
            font-weight: 600;
            gap: 12px;

            .checkbox-container {

                display: block;
                position: relative;
                padding-left: 28px;
                margin-bottom: 28px;
                cursor: pointer;
                user-select: none;

                input {
                    position: absolute;
                    opacity: 0;
                    width: 0;
                    height: 0;

                    &:checked ~ .checkmark {
                        background-color: colors.$primary;
                    }

                    &:checked ~ .checkmark::after {
                        display: block;
                    }
                }

                .checkmark {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 24px;
                    width: 24px;
                    border: 2px solid;
                    border-radius: 8px;
                    border-color: colors.$primary;

                    &::after {
                        content: "";
                        position: absolute;
                        display: none;

                        left: 9px;
                        top: 5px;
                        width: 5px;
                        height: 10px;
                        border: solid #fff;
                        border-width: 0 3px 3px 0;
                        transform: rotate(45deg);
                    }
                }

                &:hover input ~ .checkmark {
                    background-color: rgba(255, 255, 255, 0.2);
                }

                &:hover input:checked ~ .checkmark {
                    background-color: rgba(colors.$primary, 0.9);
                }

            }
        }

        button.signin-button {
            background-color: colors.$primary;
            color: #fff;
            font-size: 16px;
            font-weight: 700;
            max-width: 80vw;
            width: 380px;
            height: 58px;
            border-radius: 100px;border: 0px;
            padding: 18px 16px 18px 16px;
        }

        #signin-forgot-password {
            display: flex;
            justify-content: center;
            gap: 5px;
            margin-top: 12px;
            font-size: 14px;

            a {
                text-decoration: none;
                cursor: pointer;
            }
        }

        #signin-social {
            #signin-social-separator {

                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 16px;
                font-size: 18px;
                font-weight: 600;
                margin-top: 12px;

                hr {
                    background-color: #daf;
                    border: 0px;
                    max-width: 20vw;
                    width: 96px;
                    height: 1px;
                }
            }

            #signin-social-buttons {

                display: flex;
                align-items: center;
                justify-content: space-evenly;
                margin-top: 24px;

                button {
                    background-color: colors.$tertiary;
                    border: 1px solid #353f39;
                    //width: 88px;
                    width: 120px;
                    height: 60px;
                }

                @media only screen and (min-width: 480px) {
                    button {
                        border-radius: 16px;
                        height: 60px;
                    }
                }

                @media only screen and (max-width: 480px) {
                    button {
                        border-radius: 12px;
                        height: 40px;
                        padding: 0px;
                        padding-top: 2px;

                        img {
                            width: 18px;
                        }
                    }
                }
            }
        }

        #signin-signup-text {
            display: flex;
            justify-content: center;
            gap: 5px;
            font-size: 14px;

            @media only screen and (min-width: 480px) {
                margin-top: 30px;
            }

            @media only screen and (max-width: 480px) {
                margin-top: 12px;
            }

            a {
                text-decoration: none;
                cursor: pointer;
            }
        }

    }
}
@use "../colors.scss";

$height: 700px;
$width: 280px;

.slider {
    display: flex;
    justify-content: center;
    width: 100%;
    height: $height;

    @media only screen and (min-width: 600px) {
        max-width: 480px;
    }

    @media only screen and (max-width: 600px) {
        max-width: calc(100vw - 24px);
    }

    .help-container {
        position: absolute;

        &.hide {
            display: none;
        }

        & > div {
            position: relative;
            z-index: 2;
            width: 100%;
            height: $height;
            display: flex;
            align-items: center;
            justify-content: center;

            & > div {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            @media only screen and (min-width: 600px) {
                max-width: 480px;
            }
        
            @media only screen and (max-width: 600px) {
                max-width: calc(100vw - 24px);
            }
        }

        .icon {
            animation: swipe 2s infinite;
            
            .hand {
                position: absolute;

                .white {
                    position: absolute;
                    z-index: 3;
                    color: #fff;
                }

                .black {
                    position: relative;
                    top: 1px;
                    left: 1px;
                    z-index: 2;
                    color: #000;
                    opacity: 0.5;
                }
            }
        }

        .text {
            text-shadow: 0px 0px 20px colors.$primary, 0px 0px 30px colors.$primary, 0px 0px 40px colors.$primary, 0px 0px 50px colors.$primary, 0px 0px 60px colors.$primary, 0px 0px 70px colors.$primary, 0px 0px 80px colors.$primary, 0px 0px 90px colors.$primary;
        }
    }

    .tracker {
        position: absolute;
        z-index: 5;
        width: 100%;
        height: $height;
        overflow-y: clip;

        div {
            height: $height;
        }
        
        @media only screen and (min-width: 600px) {
            max-width: 480px;
            overflow-x: auto;

            &::-webkit-scrollbar-track, ::-webkit-scrollbar-button, ::-webkit-scrollbar-corner {
                background: transparent; 
            }
        }

        @media only screen and (max-width: 600px) {
            max-width: calc(100vw - 24px);
            overflow-x: scroll;
            -webkit-overflow-scrolling: touch;

            &::-webkit-scrollbar {
                width: 0px;
            }

            &::-webkit-scrollbar-track, &::-webkit-scrollbar-button, &::-webkit-scrollbar-corner, &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-thumb:hover {
                background: transparent; 
            }
        }
    }

    .barrier {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: $height;
        overflow: clip;

        @media only screen and (min-width: 600px) {
            max-width: 480px;
        }
    
        @media only screen and (max-width: 600px) {
            max-width: calc(100vw - 24px);
        }
    }

    ul.lane {
        display: flex;
        height: 100%;
        padding: 0px;

        li.item {
            position: absolute;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.2s linear;

            div {
                position: relative;
                height: calc($height - 20px);
                max-width: $width;
                display: flex;
                align-items: center;
                justify-content: center;

                &.blur {
                    //backdrop-filter: blur(5px);

                    img {
                        filter: blur(2px);
                    }
                }

                img {
                    box-shadow: 0px 0px 25px 5px colors.$primary;

                    &.not-init {
                        filter: brightness(50%);
                    }
                }
            }
        }
    }
}

@keyframes swipe {
    0% {
        opacity: 0;
        transform: translateX(3.5vw);
    }
    25% {
        opacity: 1;
        transform: translateX(3.5vw);
    }
    75% {
        opacity: 1;
        transform: translateX(-3.5vw) rotate(-15deg);
    }
    100% {
        opacity: 0;
        transform: translateX(-3.5vw) rotate(-15deg);
    }
}
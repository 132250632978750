#verification {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    a {
        cursor: pointer;
    }

    #verification-explanation {
        width: 100%;
        text-align: center;
    }

    .verification-input-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 12px;

        .label {
            padding-left: 16px;
            font-size: 12px;
            padding-bottom: 6px;
        }
    }

    #password-requirements {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 16px;
        font-size: 14px;
        height: 0px;
        overflow: hidden;
        transition: height 0.2s linear;

        &.open {
            height: 155px;
        }

        .requirement-valid, .requirement-invalid, .requirement-recommended {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        .requirement-valid {
            color: #0c4;
        }

        .requirement-recommended {
            color: #c80;
            & > * {
                padding-left: 5px;
                padding-right: 1px;
            }
        }

        .requirement-invalid {
            color: #c04;

            & > * {
                padding-right: 1px;
            }
        }
    }

    .verification-button-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 16px;
    }

    .verification-bottom {
        margin-top: 16px;
    }
}
#signout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    width: 100%;
    min-height: calc(100vh - 264px);

    #signout-text {
        font-size: 24px;
    }
}
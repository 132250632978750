@use "../../../colors";

.form-input-text {

    background-color: colors.$tertiary;
    background-image: linear-gradient(60deg, rgba(colors.$color1, 0.7), rgba(colors.$color2, 0.7));
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 80vw;
    width: 380px;
    height: 60px;
    border-radius: 16px;
    padding: 0px 20px 0px 20px;
    gap: 14px;
    cursor: text;

    input {
        width: 100%;
        background-color: transparent;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        outline: 0px;
        border: 0px;

        &::placeholder {
            color: rgba(colors.$secondary, 0.6);
        }
    }
}
@use "../../colors.scss";

#home {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: colors.$tertiary;
    color: colors.$tertiary;
    height: calc(100vh - 1px);
    border-bottom: 1px #fff solid;
    box-shadow: 0px 0px 25px 15px colors.$primary;
    overflow: hidden;

    @media only screen and (min-width: 600px) {
        width: 100%;
    }

    @media only screen and (max-width: 600px) {
        width: 100vw;
    }

    .passify-logo {
        position: absolute;
        z-index: 2;
        top: 30px;
        width: 100%;
        display: flex;
        justify-content: center;
        opacity: 0.4;

        img {
            border: 0;

            @media only screen and (min-width: 600px) {
                width: 70px;
            }
    
            @media only screen and (max-width: 600px) {
                width: 60px;
            }
        }
    }

    video {
        height: 100vh;
        transition: all 0.75s linear;

        &.reverse {
            transform: scaleX(-1);
        }

        &.video-desktop {
            aspect-ratio: 16 / 9;
        }

        &.video-mobile {
            aspect-ratio: 9 / 16;
        }

        @media only screen and (min-width: 600px) {
            &.video-desktop {
                display: block;
            }

            &.video-mobile {
                display: none;
            }
        }

        @media only screen and (max-width: 600px) {
            &.video-desktop {
                display: none;
            }

            &.video-mobile {
                display: block;
            }
        }
    }

    .event-date {
        position: absolute;
        top: calc(30px + 15vh);
        left: 0px;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        color: colors.$secondary;
        font-size: 30px;
        font-weight: bold;
        text-shadow: 0px 0px 10px colors.$primary, 0px 0px 20px colors.$primary;

        small {
            font-size: 12px;
        }
    }

    .cta-container {
        position: absolute; 
        top: 0px;
        left: 0px;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        button {
            border: 1px solid colors.$secondary;
            border-radius: 0px;
            background-color: transparent;
            color: colors.$secondary;
            box-shadow: 0px 0px 25px 5px colors.$primary;
            font-weight: bold;

            @media only screen and (min-width: 600px) {
                margin-top: 75vh;
            }
    
            @media only screen and (max-width: 600px) {
                margin-top: 60vh;
            }
        }
    }
}
@use "../../colors";

#pass {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: calc(100vh - 264px);

    #pass-disclaimer {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;

        #pass-disclaimer-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 16px;
            max-width: 400px;
            width: 60vw;
            
        }
    }

    .qr-code-holder {

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;

        & > .qr-code {
            background-color: colors.$primary;
            border-radius: 15px;
            padding: 15px;
            width: 256px;
            height: 256px;
        }

        & > .qr-code-progressbar-container {
            background-color: rgba(colors.$primary, 0.4);
            border-radius: 8px;
            width: 286px;
            height: 16px;

            & > .qr-code-progressbar {
                position: absolute;
                background-color: colors.$primary;
                border-radius: 8px;
                height: 16px;
            }
        }

        .passify-logo {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 16px;
            width: 100%;
            opacity: 0.7;
        }
    }
}
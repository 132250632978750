@use "../../colors.scss";

#ticket-selection {
    padding-left: 16px;
    padding-right: 16px;
    width: calc(100% - 32px);

    .code-active-prompt {
        text-align: center;
        font-size: 20px;
        margin-bottom: 32px;
    }

    .segment {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        gap: 8px;
        margin-bottom: 16px;

        .segment-name {
            font-size: 18px;
            padding-bottom: 2px;
            border-bottom: 1px solid rgba(colors.$link-color, 0.6);
            width: 100%;
        }

        .segment-batches {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 8px;
            width: 100%;

            .batch {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                width: 100%;

                .batch-info {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    gap: 4px;

                    .batch-name {

                    }

                    .batch-price {
                        font-size: 12px;
                    }
                }

                .batch-selection-quantity {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: center;
                    gap: 8px;

                    .batch-selector {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .batch-quantity-display {
                            width: 50px;
                            text-align: center;
                        }

                        button {
                            width: 24px;
                            height: 24px;
                            min-height: auto;
                            padding: 0px;
                            border: 2px solid colors.$secondary;
                            border-radius: 50%;
                            color: colors.$secondary;
                            background-color: transparent;
                        }
                    }

                    .batch-subtotal {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .summary {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
        width: 100%;
        margin-top: 32px;

        .summary-item {
            width: 100%;
            text-align: right;
            font-size: 14px;

            &.discount {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                gap: 4px;
                font-size: 12px;
                margin-bottom: 8px;

                .discount-form {
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    input {
                        width: 80px;
                        background-color: colors.$link-color;
                        background-image: linear-gradient(60deg, rgba(colors.$color1, 0.7), rgba(colors.$color2, 0.7));
                        border-radius: 0.5em;
                        border: 1px solid colors.$secondary;
                        color: colors.$secondary;
                        padding: 4px;
                        font-size: 13px;
                    }

                    button {
                        width: 24px;
                        height: 24px;
                        min-height: auto;
                        padding: 0px;
                        border: 0px;
                        border-radius: 50%;
                        color: colors.$secondary;
                        background-color: colors.$primary;
                    }
                }
            }

            &.total {
                border-top: 1px solid rgba(colors.$link-color, 0.6);
                font-weight: bold;
                padding-top: 8px;
            }
        }
    }

    .checkout-button-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 32px;
    }

    .passify-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 32px;
        width: 100%;
        opacity: 0.7;
    }
}
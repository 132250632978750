@use "./colors.scss";
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@100;400;600;700&display=swap');

$header-size: 64px;

#app {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    header#app-header {
		width: 100%;
		height: 80px;
		display: flex;
		align-items: center;
        justify-content: space-between;
		color: #fff;

		h1 {
			margin: 0px;
		}

		#app-logo {
			margin: 28px 16px 0px 16px;
			height: 52px;
		}

        .app-header-corner {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 28px;
            width: 52px;
            height: 52px;
            font-size: 18px;

            &.left {
                margin-left: 14px;
            }

            &.right {
                margin-right: 14px;
            }
        }
	}

    #app-body {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        max-width: 480px;
        padding-top: 32px;
        padding-bottom: 32px;
        margin-bottom: 68px;
    }

    #app-navbar {
        position: fixed;
        bottom: 0px;
        left: 0px;
        z-index: 6;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 48px;
        background-color: colors.$primary;
        background-image: linear-gradient(60deg, rgba(colors.$color3, 0.8), rgba(colors.$color4, 0.8));
        border-top: 1px solid colors.$primary;
        overflow: hidden;

        ul {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            list-style-type: none;
            margin: 0;
            padding: 0;
            overflow: hidden;
            width: 100%;
            max-width: 480px;

            li {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 48px;
                height: 48px;
                font-size: 18px;
                cursor: pointer;

                &.active {
                    color: colors.$link-color;
                }
            }
        }
    }
}
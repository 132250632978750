@use "../../colors.scss";

#wallet {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 32px);
    padding-left: 16px;
    padding-right: 16px;

    #wallet-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
        width: 100%;

        .wallet-pass-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 8px;
            width: 100%;

            .wallet-pass {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 0.5em;
                width: calc(100% - 1.6em);
                padding: 0.8em;
                border-radius: 0.8em;
                background-color: rgba(colors.$primary, 0.8);
                cursor: pointer;

                &.used {
                    filter: grayscale(0.8);
                    cursor: default;
                }

                .wallet-pass-event-image {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    aspect-ratio: 1;
                    border-radius: 0.8em;
                    overflow: hidden;

                    img {
                        width: 100%;
                        object-fit: cover;
                    }
                }

                .wallet-pass-event-name {
                    font-size: 14px;
                    font-weight: bold;
                }

                .wallet-pass-batch-name {
                    font-size: 12px;
                }

                .wallet-pass-quantity {
                    font-weight: bold;
                }
            }

            .transfer-button-container {
                width: 100%;

                button {
                    width: 100%;
                    font-size: 14px;
                    padding: 0px;
                    height: 32px;
                }
            }
        }
    }

    .wallet-prompt {
        text-align: center;
        width: 100%;
    }
}
#register-phone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;

    .register-phone-prompt {
        text-align: center;
    }
}
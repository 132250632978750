@use "../../colors.scss";

#review-summary {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;

    #review-summary-table {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
        width: calc(100% - 64px);
        border-radius: 0.5em;
        background-color: rgba(#fff, 0.15);
        padding: 16px;

        .review-summary-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            font-size: 14px;
        }

        #review-summary-total {
            border-top: 1px solid rgba(colors.$link-color, 0.6);
            padding-top: 8px;
            font-weight: bold;
        }
    }

    .unsigned-title {
        width: calc(100% - 32px);
        padding-left: 16px;
        padding-right: 16px;
        text-align: center;
        margin-bottom: 16px;
    }

    .unsigned-form-field {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .label {
            padding-left: 16px;
            font-size: 12px;
            padding-bottom: 6px;
        }
    }

    .payment-button-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px;
        width: 100%;
        
        button {
            width: 150px;
            height: 48px;
            font-size: 13px;

            small {
                font-size: 11px;
            }
        }
    }

    .passify-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 16px;
        width: 100%;
        opacity: 0.7;
    }
}
@use "../../colors.scss";

#payment {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    #payment-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 12px;
        width: 100%;

        .payment-form-field {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .label {
                padding-left: 16px;
                font-size: 12px;
                padding-bottom: 6px;
            }
        }

        .payment-form-row {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 24px;

            @media only screen and (min-width: 600px) {
                .form-input-text {
                    width: 158px;
                }
            }

            @media only screen and (max-width: 600px) {
                width: calc(100% - 36px);

                .form-input-text {
                    width: calc(100% - 40px);
                }
            }
        }

        .pay-button-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .qr-code-explain {
            width: calc(100% - 32px);
            padding-left: 16px;
            padding-right: 16px;
            text-align: center;
            margin-bottom: 16px;
        }

        .qr-code-holder {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 16px;
            width: 100%;
    
            & > .qr-code {
                background-color: colors.$primary;
                border-radius: 15px;
                padding: 15px;
                width: 256px;
                height: 256px;
            }

            .qr-code-copy {
                cursor: default;
            }
        }
    }

    .passify-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 32px;
        width: 100%;
        opacity: 0.7;
    }
}
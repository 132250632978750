#forgot-password {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;

    .forgot-password-prompt {
        width: calc(100% - 32px);
        padding-left: 16px;
        padding-right: 16px;
        text-align: center;
    }
}
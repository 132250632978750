@use "../../colors";

#signup {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    #signup-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;

        .signin-form-input-text {

            background-color: colors.$tertiary;
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 80vw;
            width: 380px;
            height: 60px;
            border-radius: 16px;
            padding: 0px 20px 0px 20px;
            gap: 14px;
            cursor: text;

            input {
                width: 100%;
                background-color: transparent;
                color: colors.$secondary;
                font-size: 14px;
                font-weight: 400;
                outline: 0px;
                border: 0px;
            }
        }

        .signin-form-input-checkbox {

            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 18px;
            font-weight: 600;
            gap: 12px;

            .checkbox-container {

                display: block;
                position: relative;
                padding-left: 28px;
                margin-bottom: 28px;
                cursor: pointer;
                user-select: none;

                input {
                    position: absolute;
                    opacity: 0;
                    width: 0;
                    height: 0;

                    &:checked ~ .checkmark {
                        background-color: colors.$primary;
                    }

                    &:checked ~ .checkmark::after {
                        display: block;
                    }
                }

                .checkmark {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 24px;
                    width: 24px;
                    border: 2px solid;
                    border-radius: 8px;
                    border-color: colors.$primary;

                    &::after {
                        content: "";
                        position: absolute;
                        display: none;

                        left: 9px;
                        top: 5px;
                        width: 5px;
                        height: 10px;
                        border: solid #fff;
                        border-width: 0 3px 3px 0;
                        transform: rotate(45deg);
                    }
                }

                &:hover input ~ .checkmark {
                    background-color: rgba(255, 255, 255, 0.2);
                }

                &:hover input:checked ~ .checkmark {
                    background-color: rgba(colors.$primary, 0.9);
                }

            }
        }

        button.signin-button {
            background-color: colors.$primary;
            color: #fff;
            font-size: 16px;
            font-weight: 700;
            max-width: 80vw;
            width: 380px;
            height: 58px;
            border-radius: 100px;border: 0px;
            padding: 18px 16px 18px 16px;
        }

        #password-requirements {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 16px;
            font-size: 14px;
            height: 0px;
            overflow: hidden;
            transition: height 0.2s linear;

            &.open {
                height: 155px;
            }
    
            .requirement-valid, .requirement-invalid, .requirement-recommended {
                display: flex;
                align-items: center;
                gap: 8px;
            }
    
            .requirement-valid {
                color: #0c4;
            }
    
            .requirement-recommended {
                color: #c80;
                & > * {
                    padding-left: 5px;
                    padding-right: 1px;
                }
            }
    
            .requirement-invalid {
                color: #c04;
    
                & > * {
                    padding-right: 1px;
                }
            }
        }

        .terms {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            gap: 8px;
        }

        #signup-signin-text {
            display: flex;
            justify-content: center;
            gap: 5px;
            margin-top: 12px;
            font-size: 14px;

            a {
                text-decoration: none;
                cursor: pointer;
            }
        }
    }
}
@use "../../colors.scss";
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@100;400;600;700&display=swap');

#cyberpunk {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    #cyberpunk-title {
        position: relative;
        z-index: 5;
        margin-bottom: -64px;
        font-family: 'Orbitron';
        font-size: 2em;
        font-weight: bold;
        text-align: center;
        text-shadow: 0 0 10px #fff, 0 0 20px rgba(colors.$primary, 1), 0 0 30px colors.$primary;
    }
}
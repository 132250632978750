@use "../../../colors";

.form-input-checkbox {

    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600;
    gap: 12px;

    .checkbox-container {

        display: block;
        position: relative;
        padding-left: 20px;
        margin-bottom: 20px;
        cursor: pointer;
        user-select: none;

        input {
            position: absolute;
            opacity: 0;
            width: 0;
            height: 0;
        }

        .checkedbox, .uncheckedbox {
            position: absolute;
            top: 0;
            left: 0;
            height: 16px;
            width: 16px;
            border: 2px solid;
            border-radius: 6px;
            border-color: colors.$primary;
        }

        .uncheckedbox:hover {
            background-color: rgba(255, 255, 255, 0.2);
        }

        .checkedbox {
            background-color: colors.$primary;

            &:hover {
                background-color: rgba(colors.$primary, 0.9);
            }

            .checkmark {
                position: absolute;
                left: 5px;
                top: 2px;
                width: 3px;
                height: 8px;
                border: solid #fff;
                border-width: 0 3px 3px 0;
                transform: rotate(45deg);
            }
        }
    }
}


@use "../../../colors";

.form-button {
    button {
        font-size: 16px;
        border-radius: 100px;
    }

    button.button-large {
        font-weight: 700;
        max-width: 80vw;
        width: 380px;
        height: 58px;
        padding: 18px 16px 18px 16px;
    }

    button.button-small {
        font-weight: 600;
        height: 38px;
        padding: 8px 20px;
    }

    button.button-primary {
        background-color: colors.$primary;
        color: #fff;
        border: 0px;
    }

    button.button-secondary {
        background-color: transparent;
        color: colors.$primary;
        border: 2px solid colors.$primary;
    }

    button.button-disabled {
        background-color: #aaa;
        color: rgba(#fff, 0.8);
    }

    button {
        .loading-progress {
            position: relative;
            width: 18px;
            height: 18px;

            .circle {
                position: absolute;
                right: 0px;
                width: 100%;
                height: 100%;
                border: solid 3px transparent;
                border-top-color:  colors.$primary;
                border-right-color:  colors.$primary;
                border-radius: 50%;
                transform: rotate(135deg);  
                animation: spin 0.6s steps(2) .2s infinite;
            }
        }
    }
}

@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
@use "./colors.scss";
@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@100;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400;600;700&display=swap');

body {
  margin: 0;
  color: #fff;
  background-color: colors.$tertiary;
  background-image: url("../public/images/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  font-family: 'Urbanist', 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: colors.$link-color;
  text-shadow: 0px 0px 10px colors.$primary, 0px 0px 20px colors.$primary;
}

input, button {
  font-family: 'Urbanist', 'Inter', sans-serif;
  border-radius: 8px;
  padding: 8px;
}

button.invisible {
  background: transparent;
  border: 0px;
}

.grecaptcha-badge {
  visibility: hidden !important;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track, ::-webkit-scrollbar-button, ::-webkit-scrollbar-corner {
  background: colors.$tertiary; 
}

::-webkit-scrollbar-thumb {
  background: rgba(colors.$primary, 0.4); 
}

::-webkit-scrollbar-thumb:hover {
  background: colors.$primary; 
}